import styled, { css } from 'styled-components';
import { TagBannerSizes } from 'types/centres';

export const CentreItemStyles = styled.div`
  height: 100%;
  .selected-card-item {
    border: 1px solid var(--primary);
  }
  .centre-item {
    &__content {
      font-size: 14px;
      > div:not(:first-child) {
        margin-top: 8px;
      }
    }

    &__info-item {
      display: flex;
      align-items: center;
      line-height: 16px;
      .anticon {
        margin-right: 6px;
        color: var(--primary);
      }
      &-value {
        color: var(--text-primary);
      }
    }
  }

  .ant-card-body {
    padding: 16px 12px;
  }

  @media only screen and (max-width: 576px) {
    .ant-card-body {
      padding: 12px;
    }
  }
`;

export const PopularTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  text-align: center;
  padding: 7px 6px 7px 4px;
  background: #fff4ec;
  border: 1px solid #ffffff;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  color: #f98437;

  .anticon {
    color: currentColor;
    font-size: 11px;
    margin-right: 3.7px;
  }
`;

export const BannerCentreItemStyles = styled.div`
  --corner-space: 12px;
  --bottom-left-tag-space: 8px;
  --tag-custom-font-size: 12px;
  --button-top-size: 32px;
  --rating-font-size: 12px;
  --tag-height: 32px;
  --NQSR-width: 58px;

  .centre-item {
    &__cover-wrapper {
      position: relative;
    }
    &__rating-btn {
      background: var(--primary);
      font-size: var(--rating-font-size) !important;
      width: var(--button-top-size) !important;
      height: var(--button-top-size) !important;

      img {
        width: 100%;
        height: 100%;
      }
    }
    &__rating-img {
      width: 100%;
    }

    &__favorite {
      position: absolute;
      top: var(--corner-space);
      right: var(--corner-space);
      border: none;
      background-color: #2d1f2120;
      width: var(--button-top-size) !important;
      height: var(--button-top-size) !important;
    }
    &__NQSRType-img {
      width: 64px;
      height: 32px;
      object-fit: contain;
      background: #ffffff;
      border-radius: 6px;
      position: absolute;
      bottom: var(--corner-space);
      right: var(--corner-space);
      width: var(--NQSR-width);
      height: var(--tag-height);
    }
    &__logo {
      border: 1px solid var(--border-color-light) !important;
      object-fit: cover;
      border-radius: 50%;
    }
    &__group-tag {
      position: absolute;
      top: var(--corner-space);
      left: var(--corner-space);
    }
  }

  .tag-custom {
    height: var(--button-top-size) !important;
    font-size: var(--tag-custom-font-size);
    border-radius: 8px;
  }

  .block-btn {
    color: #fff;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    .anticon {
      font-size: 18px !important;
      line-height: 0;
    }
  }

  ${PopularTag} {
    height: var(--tag-height);
  }

  ${({ tagSize, isListViewItem }) =>
    tagSize === TagBannerSizes.LARGE &&
    isListViewItem &&
    css`
      --tag-size: 56px;
      --corner-space: 20px;
      --bottom-left-tag-space: 16px;
      --tag-height: 48px;
      --NQSR-width: 96px;
      --popular-font-size: 14px;
      --rating-font-size: 20px;
      --tag-custom-font-size: 18px;
      --button-top-size: 48px;


      .tag-custom {
        border-radius: 12px;
      }


      .centre-item {
        &__rating-btn {
          font-size: var(--rating-font-size) !important;
          line-height: 30px;
          border-radius: 12px;
        }
        &__favorite {
          .anticon {
            font-size: 24px !important;
          }
        }

        &__logo {
          width: var(--tag-size) !important;
          height: var(--tag-size) !important;
          margin-right: 16px;
        }
      }

      ${PopularTag} {
        padding: 6px 10px 6px 8px;
        font-size: var(--popular-font-size);

        .anticon {
          font-size: 16px;
        }
      }

      @media only screen and (min-width: 577px) {
        .tag-custom {
          padding: 12px 16px;
        }
      }

      @media only screen and (max-width: 576px) {
        --NQSR-width: 58px;
        --tag-size: 40px;
        --corner-space: 12px;
        --bottom-left-tag-space: 8px;
        --tag-height: 32px;
        --popular-font-size: 12px;
        --rating-font-size: 12px;
        --tag-custom-font-size: 12px;

        ${PopularTag} {
          padding: 7px 6px 7px 4px;
        }

        .centre-item__logo {
            margin-right: 8px;
        }

        .centre-item__rating-btn {
          border-radius: 8px;
        }

        .centre-item__favorite {
          .anticon {
            font-size: 18px !important;
          }
        }
    `}

  ${({ tagSize }) =>
    tagSize === TagBannerSizes.MAP_SIZE &&
    css`
      --button-top-size: 48px;
      --rating-font-size: 16px;
      --tag-custom-font-size: 16px;
      --tag-height: 40px;
      --NQSR-width: 80px;

      @media only screen and (min-width: 577px) {
        ${PopularTag} {
          padding: 6px 10px 6px 8px;
        }

        .centre-item {
          &__logo {
            width: 48px !important;
            height: 48px !important;
          }
        }
      }
    `}

  @media only screen and (max-width: 576px) {
    --button-top-size: 32px;
    --rating-font-size: 12px;
    --tag-custom-font-size: 12px;
    --tag-height: 32px;
    --NQSR-width: 58px;

    .tag-custom {
      border-radius: 8px;
      font-size: 12px;
    }

    .centre-item {
      &__rating-btn {
        font-size: 12px !important;
      }
    }
  }
`;

export const CostPerDayStyles = styled.div`
  .tag-cost-special-offer {
    border: 1px solid #fff;
    outline: var(--primary) solid 1px;
    height: 24px;
    font-weight: bold;
    font-size: 8px !important;
    text-transform: uppercase;
  }
`;

export const BottomLeftTags = styled.div`
  position: absolute;
  bottom: ${({ hasLogo }) =>
    hasLogo ? 'var(--bottom-left-tag-space)' : 'var(--corner-space)'};
  left: var(--corner-space);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CentreName = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: var(--text-black-neutral);
  margin-right: 8px;
  margin-bottom: 8px;

  @media only screen and (max-width: 576px) {
    font-size: 16px;
  }
  ${({ isFeaturedServicesOfSuburb }) =>
    isFeaturedServicesOfSuburb &&
    css`
      font-size: 16px;
    `}
`;
