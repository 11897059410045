import { Space } from 'antd';
import { getFeaturedImage } from 'utils/dataUtils';
import TagCustom from '@uikit/commons/TagCustom';
import { useMemo } from 'react';
import { CentreItem, TagBannerSizes } from 'types/centres';
import { useTranslation } from 'react-i18next';
import { PopularIcon } from '@uikit/commons/SVGIcons';
import NextImage from '@uikit/commons/NextImage';
import KindiRating from '@uikit/commons/KindiRating';
import CoverImageRatio from '@uikit/items/CoverImageRatio';
import { BannerCentreItemStyles, BottomLeftTags, PopularTag } from './styles';
import FavoriteButton from './FavoriteButton';

interface Props {
  item?: Partial<CentreItem>;
  showLogo?: boolean;
  logoSize?: number;
  fullWidth?: boolean;
  tagSize?: TagBannerSizes;
  isListViewItem?: boolean;
  sizes?: string;
}

const BannerCentreItem = ({
  item,
  showLogo,
  logoSize = 40,
  fullWidth,
  tagSize = TagBannerSizes.MEDIUM,
  isListViewItem,
  sizes,
}: Props) => {
  const { t } = useTranslation();

  const tagFeature = useMemo(() => {
    if (item?.isFeatured) {
      return (
        <TagCustom
          color="#fff"
          backgroundColor="var(--primary)"
          title="centres.featured"
        />
      );
    }
    if (item?.isSpecialOffer) {
      return (
        <TagCustom
          color="#fff"
          backgroundColor="var(--primary)"
          title="centres.specialOffer"
        />
      );
    }
    return null;
  }, [item]);
  const centreLogo = useMemo(() => {
    if (item?.brand?.logo || item?.logo?.url) {
      return (
        <div className="mr-8 d-flex">
          <NextImage
            alt="kindicare"
            src={item?.brand?.logo || item?.logo?.url}
            width={logoSize}
            height={logoSize}
            className="centre-item__logo"
          />
        </div>
      );
    }

    return null;
  }, [item, logoSize]);

  return (
    <BannerCentreItemStyles
      fullWidth={fullWidth}
      tagSize={tagSize}
      isListViewItem={isListViewItem}
    >
      <div className="centre-item__cover-wrapper">
        <CoverImageRatio
          fullWidth={fullWidth}
          sizes={sizes}
          src={
            item?.featuredImageUrl ||
            item?.image ||
            getFeaturedImage(item?.images)
          }
        />
        <Space wrap className="centre-item__group-tag ">
          <KindiRating
            className="block-btn centre-item__rating-btn"
            kindiCareRating={item?.kindiCareRating}
            kindiCareRatingImage={item?.kindiCareRatingImage}
            width={38}
            height={38}
          />
          <span className="centre-item__rating-btn">{tagFeature}</span>
        </Space>
        <FavoriteButton centreId={item?.id} />
        {item?.NQSRType?.image && (
          <div className="centre-item__NQSRType-img">
            <NextImage
              alt="NQSR Type"
              className="rounded-6 object-contain"
              src={item?.NQSRType?.image}
              layout="fill"
            />
          </div>
        )}

        <BottomLeftTags hasLogo={!!centreLogo}>
          {showLogo && centreLogo}
          {item?.isPopular && (
            <PopularTag>
              <PopularIcon />
              <span>{t('common.popular')}</span>
            </PopularTag>
          )}
        </BottomLeftTags>
      </div>
    </BannerCentreItemStyles>
  );
};

export default BannerCentreItem;
