import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addFavoriteCentres, deleteFavoriteCentres } from '@redux/auth/actions';
import RequiredAuthButton from 'components/rest/RequiredAuthButton';
import {
  RESOURCE_CENTRES_FAVORITES,
  utilCentresFavourites,
} from '@redux/centresFavourites';
import { HeartFilledIcon, HeartOutlineIcon } from '@uikit/commons/SVGIcons';
import { removeItemInFavoriteList } from '@redux/centresFavoriteComparison/slice';
import { useRouter } from 'next/router';

const FavoriteButton = ({ centreId, ...props }) => {
  const dispatch = useDispatch();
  const { query } = useRouter();

  const [loading, setLoading] = useState(false);
  const favoriteCentreIds = useSelector(
    (state) => state.auth.data?.favoriteCentreIds,
  );
  const isFavorite = favoriteCentreIds?.includes(centreId);

  const favoriteCentre = async (e) => {
    e?.preventDefault();
    setLoading(true);
    if (isFavorite) {
      query?.isCompareAll && dispatch(removeItemInFavoriteList(centreId));
      await dispatch(deleteFavoriteCentres(centreId));
    } else {
      await dispatch(addFavoriteCentres(centreId));
    }
    await dispatch(
      utilCentresFavourites.invalidateTags([
        { type: RESOURCE_CENTRES_FAVORITES, id: 'LIST' },
      ]),
    );
    setLoading(false);
  };

  return (
    <RequiredAuthButton
      className="block-btn centre-item__favorite"
      onClick={favoriteCentre}
      loading={loading}
      icon={
        isFavorite ? (
          <HeartFilledIcon style={{ color: 'var(--primary)' }} />
        ) : (
          <HeartOutlineIcon />
        )
      }
      {...props}
    />
  );
};

FavoriteButton.propTypes = {
  centreId: PropTypes.string,
};

export default FavoriteButton;
