export interface ResourceDataCentreList {
  distance?: number;
  filter?: string;
  householdTaxableIncome?: number | null;
  latitude?: string;
  longitude?: string;
  limit?: number;
  numberOfPages?: number;
  offset?: number;
  orderBy?: string;
  outsideFilter?: object;
  parentActivityLevelId?: string | null;
  total?: number;
  location?: {
    latitude?: string;
    longitude?: string;
  };
  page?: number;
  size?: number;
  q?: string;
  searchType?: string;
  brandId?: string;
  postcode?: number;
  state?: string;
  data?: ResourceDataCentreList;
  options?: object;
}

export interface CentreMarker {
  NQSROverallTypeId?: string;
  NQSRType?: object;
  addressL1?: string;
  bestService?: object;
  brand?: object;
  city?: string;
  country?: string;
  dateApplied?: string;
  displayAddress?: string;
  displayAfternoonSchoolTermTime?: [];
  displayHolidayTime?: [];
  distance?: string;
  geolocation?: {
    latitude?: string;
    longitude?: string;
  };
  id?: string;
  images?: object[];
  isFavoriteCentre?: boolean;
  isFeatured?: boolean;
  isMarketing?: boolean;
  isPopular?: boolean;
  isSpecialOffer?: boolean;
  kindiCareRating?: string;
  latestViewCount?: string;
  name?: string;
  maxServiceCostPerDay?: number | string;
  maxServiceSecondSubsidyCost?: number | string;
  maxServiceSubsidyCost?: number | string;
  minServiceCostPerDay?: number | string;
  minServiceSecondSubsidyCost?: number | string;
  minServiceSubsidyCost?: number | string;
  openTime?: object;
  phone?: string;
  postCode?: string;
  reviewCount?: number;
  reviewStarAverage?: string;
  slug?: string;
  state?: string;
  viewsCount?: string;
}

export interface SummaryCentreList {
  min: number;
  max: number;
  total: number;
}

export interface CentreItem {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  businessName: string;
  images: Media[];
  image: string;
  slug: string;
  geolocation: Geolocation;
  shortDescription: string;
  longDescription: string | null;
  dateApplied: Date;
  yearEstablish: string | null;
  placeAllowed: string | null;
  businessAbn: string | null;
  businessAcn: string | null;
  addressL1: string;
  addressL2: string | null;
  state: string;
  postCode: string;
  country: string;
  region: string | null;
  city: string;
  LGA: string | null;
  phone: string;
  mobilePhone: string | null;
  email: string;
  website: string | null;
  isActive: boolean;
  NQSROverallTypeId: string | null;
  NQSRCurrentTypeId: string | null;
  openTime: unknown;
  applicationFeeAmount: number;
  contactName: string | null;
  adminEmail: string;
  enquiryEmail: string;
  currentOverallNQSRatingDate: string | null;
  currentOverallNQSRating: string | null;
  fax: string | null;
  displayAddress: string;
  logo?: {
    url?: string;
  };
  kindiCareRating: number;
  featureIds: string[];
  isFeatured: boolean;
  providerId: string | null;
  placeId: string | null;
  placeDetail: string | null;
  claimStatus: string;
  adminEmail2: string | null;
  providerEmail: string | null;
  registeredEmail: string | null;
  moreInformation: string | null;
  subscription: string;
  type: string;
  searchText: string | null;
  isDeleted: boolean;
  governmentReferenceId: string;
  governmentCentreServiceId: string;
  maxCost: number;
  minCost: number;
  isUpdatedImages: boolean;
  updatedReviewsAt: Date;
  companyId: string;
  brandId: string;
  viewsCount: string;
  isACECQA: boolean;
  latestViewCount: string;
  reviewCount: number;
  reviewStarAverage: number;
  totalListingStrength: number;
  isStartListing: boolean;
  isFinishedStep: boolean;
  primaryChildCareServiceId: string;
  searchingText: string;
  kindiCareRatingCategoryId: string;
  isPopular: boolean;
  isSpecialOffer?: boolean;
  features: Feature[];
  distance: number;
  isFavoriteCentre: boolean;
  NQSRType?: {
    image?: string;
  };
  services: Service[];
  primaryChildCareService: PrimaryChildCareService;
  displayOpenTime: string[];
  displayHolidayTime: string[];
  displayMorningSchoolTermTime: string[];
  displayAfternoonSchoolTermTime: string[];
  interactCentreText: string;
  isEnquiryComplex: boolean;
  kindiCareRatingImage: string;
  bestService: BestService;
  priceRange: string;
  servicesOffered: ServicesOffered[];
  brandLogo: string;
  brand?: {
    logo?: string;
  };
  featuredImageUrl?: string;
}

export interface Media {
  url: string;
  type: string;
  source?: string;
  key?: string;
  updatedAt?: Date;
}

export interface Geolocation {
  latitude: number;
  longitude: number;
}

export interface Feature {
  id: number;
  name: string;
  icon: Icon;
  description: string | null;
  createdAt: string;
  updatedAt: string;
  isActive: boolean;
  categoryId: number;
}

export interface Icon {
  key: string;
  url: string;
}

export interface Service {
  id: string;
  createdAt: string;
  updatedAt: string;
  centreId: string;
  serviceTypeId: string;
  centreValueForMoney: string;
  serviceAgeGroup: ServiceAgeGroup;
  vacancy_cod: boolean;
  costPerDay: number;
  isActive: boolean;
  score: number;
  serviceAgeGroupId: string;
  capacity: number;
  roomName: string | null;
  lastUpdated: Date;
  cappedFee: number;
  sessionTypeId: string;
  priceLastUpdated: string;
  programId: string | null;
  serviceType: ServiceType;
}

export interface ServiceAgeGroup {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  type: string;
  order: number;
  isActive: boolean;
  textColor: string;
  backgroundColor: string;
  logo?: string;
}

export interface ServiceType {
  id: string;
  createdAt: string;
  updatedAt: string;
  externalId: string;
  name: string;
  icon: { url: string };
  description: string | null;
  isActive: boolean;
  sortOrder: number;
  weekPerYear: number;
  hourlyFeeCap: number;
  isShow: boolean;
  primaryChildCareServiceId: string;
}

export interface PrimaryChildCareService {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  logo: unknown;
  isActive: boolean;
  code: string;
}

export interface BestService {
  id: string;
  createdAt: string;
  updatedAt: string;
  centreId: string;
  serviceTypeId: string;
  centreValueForMoney: string;
  serviceAgeGroup: unknown;
  vacancy_cod: boolean;
  costPerDay: number;
  isActive: boolean;
  score: number;
  serviceAgeGroupId: string;
  capacity: number;
  roomName: string | null;
  lastUpdated: Date;
  cappedFee: number;
  sessionTypeId: string;
  priceLastUpdated: string;
  programId: string | null;
  serviceType: ServiceType;
}

export interface ServicesOffered {
  serviceType: string;
  ageGroup: AgeGroup[];
}

export interface AgeGroup {
  text: string;
  textColor: string;
  backgroundColor: string;
}

export interface RatingSummary {
  min?: number;
  max?: number;
  description?: string;
  kindiRatingType?: string;
  average?: number;
}

export interface KindicareRating {
  rating?: string | number;
  ratingSummary?: RatingSummary;
  kindiCareRatingImage?: string;
  visible?: boolean;
  onCancel?: () => void;
}

interface DataNQSRating {
  title?: string;
  value?: string | number;
}
interface NQSRType {
  image?: string;
  title?: string;
}

export interface NQSRating {
  NQSRType?: NQSRType;
  dataNQSRating?: DataNQSRating[];
  description?: string;
  visible?: boolean;
  onCancel?: () => void;
}

export enum TagBannerSizes {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  MAP_SIZE = 'MAP_SIZE',
}

interface CentreAppSettingData {
  text: string;
}

interface CentreAppSettingItem {
  id: string;
  key: string;
  value: string | null;
  type: string;
  isActive: boolean;
  data: CentreAppSettingData | null;
  name: string | null;
  description: string | null;
}
export interface CentreDetailsApplicableButtons {
  centreAppSettings: CentreAppSettingItem;
  isAllowedApplications: boolean;
  isAllowedBookingTour: boolean;
  isAllowedEnquiries: boolean;
}

export interface ButtonCTA {
  enable: boolean;
  externalLink: string;
  icon: string;
  id: string;
  key: null;
  name: string;
  requiredLogin?: boolean;
  trackingEventName: string;
}

export interface SimpleAndComplexEnquiry {
  centre?: CentreItem;
  onCancel?: () => void;
}
