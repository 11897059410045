import PropTypes from 'prop-types';
import TagCustom from '@uikit/commons/TagCustom';
import { useTranslation } from 'react-i18next';
import { formatCostPerDay, hasValidSubsidyCost } from 'utils/tools';
import { useSelector } from 'react-redux';
import { getUserParamsFilterSelector } from '@redux/auth/selectors';
import { CostPerDayStyles } from './styles';

const CostPerDay = ({ item }) => {
  const { t } = useTranslation();
  const validSubsidyCost = hasValidSubsidyCost(item);
  const userParams = useSelector(getUserParamsFilterSelector);

  const costPerDay = `$${
    formatCostPerDay(item?.bestService?.costPerDay) ||
    t('kindicareService.enquire')
  }`;

  const subsidyCost = `$${
    formatCostPerDay(item?.bestService?.subsidyCost) ||
    t('kindicareService.enquire')
  }*`;

  return item?.isSpecialOffer ? (
    <CostPerDayStyles>
      <TagCustom
        color="#fff"
        backgroundColor="var(--primary)"
        title={`${t('centres.specialOffer')}!`}
        className="tag-cost-special-offer"
      />
    </CostPerDayStyles>
  ) : (
    <>
      {userParams && validSubsidyCost ? (
        <div className="cost-per-day size-16 lh-24 fw-bold text-highlight-secondary text-subsidy">
          {subsidyCost}
        </div>
      ) : (
        <div className="cost-per-day size-16 lh-24 fw-bold text-highlight">
          {costPerDay}
        </div>
      )}
    </>
  );
};

CostPerDay.propTypes = {
  item: PropTypes.object,
};

export default CostPerDay;
