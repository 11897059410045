import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { getTextValueMoney } from 'utils/dataUtils';
import { DollarCircleOutlineIcon } from './SVGIcons';

interface Props {
  centreValue?: string;
  className?: string;
  valueClassName?: string;
}

const CentreServiceInfo = ({
  centreValue,
  className,
  valueClassName,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={getTextValueMoney(centreValue)}
      overlayInnerStyle={{
        background: '#27272A',
        borderRadius: 8,
        padding: '6px 10px',
      }}
    >
      <div className={className}>
        <DollarCircleOutlineIcon
          className="d-inline-flex items-center"
          svgProps={{
            width: 17,
            height: 16,
          }}
        />

        <span className={valueClassName}>
          {centreValue || t('kindicareService.notAssessed')}
        </span>
      </div>
    </Tooltip>
  );
};

export default CentreServiceInfo;
