import React from 'react';
import { formatMoney, formatRatingNumber } from 'utils/tools';
import { useTranslation } from 'react-i18next';
import { StarFilledIcon } from '@uikit/commons/SVGIcons';
import { CentreReviewInfoStyles } from './styles';

interface Props {
  reviewStarAverage?: number | string;
  reviewCount?: number;
  fontSize?: number;
}

const CentreReviewInfo = ({
  reviewStarAverage,
  reviewCount,
  fontSize,
}: Props) => {
  const { t } = useTranslation();

  return (
    reviewCount > 0 && (
      <CentreReviewInfoStyles className="flex-center" fontSize={fontSize}>
        <StarFilledIcon svgProps={{ fillColor: '#FFB912' }} />
        <div>
          <strong className="fw-700">
            {formatRatingNumber(reviewStarAverage)}
          </strong>
          <span className="review-count">
            {`(${formatMoney(reviewCount)} ${
              Number(reviewCount) === 1
                ? t('common.review')
                : t('common.reviews')
            })`}
          </span>
        </div>
      </CentreReviewInfoStyles>
    )
  );
};

export default CentreReviewInfo;
