import styled, { css } from 'styled-components';

export const CentreReviewInfoStyles = styled.div`
  strong {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #db147f;
    margin-left: 6px;
  }

  .anticon {
    font-size: 15px;
  }

  .review-count {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #758098;
    text-transform: lowercase;
    margin-left: 4px;
  }

  ${({ fontSize }) =>
    fontSize &&
    css`
      strong,
      .review-count {
        font-size: ${fontSize}px;
        line-height: unset !important;
      }

      .anticon {
        font-size: calc(${fontSize}px + 2px);
        margin-right: 0 !important;
      }
    `}

  @media (max-width: 576px) {
    strong,
    .review-count {
      font-size: 14px;
    }

    .anticon {
      font-size: 15px;
    }
  }
`;
