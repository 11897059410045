import { getCentresCompareSelector } from '@redux/centresCompare/selectors';
import {
  addCentreCompare,
  removeCentreCompare,
} from '@redux/centresCompare/slice';
import { Checkbox } from 'antd';
import { pick } from 'lodash-es';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFeaturedImage } from 'utils/dataUtils';
import { trackingEvent } from '@redux/eventTracking/actions';
import { EVENT_TRACKING } from 'configs/eventTracking';
import { getLinkDetail } from 'utils/tools';

const AddToCompare = ({
  item,
  handleClickAddItem,
  handleClickRemoveItem,
  hasAddMoreToCompare,
  listCentresAdded,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const centresCompare = useSelector(getCentresCompareSelector);
  const selectedCentrePathname = getLinkDetail(item);
  const eventData = {
    centreId: item?.id,
    pathname: selectedCentrePathname,
  };
  const isSelected = listCentresAdded?.find(
    (centre) => centre?.id === item?.id,
  );

  const handleAddToCompare = (e) => {
    const { checked } = e.target;
    setIsChecked(checked);
    if (checked) {
      hasAddMoreToCompare
        ? handleClickAddItem(item)
        : dispatch(
            addCentreCompare({
              ...pick(item, ['id', 'name']),
              image: item?.featuredImageUrl || getFeaturedImage(item?.images),
            }),
          );

      dispatch(
        trackingEvent({
          eventName: EVENT_TRACKING.centreAddedToCompare,
          eventData,
        }),
      );
    } else {
      hasAddMoreToCompare
        ? handleClickRemoveItem(item)
        : dispatch(removeCentreCompare(item?.id));

      dispatch(
        trackingEvent({
          eventName: EVENT_TRACKING.centreRemovedFromCompare,
          eventData,
        }),
      );
    }
  };

  useEffect(() => {
    const checkSelected =
      centresCompare?.findIndex((centre) => centre?.id === item?.id) > -1;
    setIsChecked(checkSelected);
  }, [centresCompare, item?.id]);

  return (
    <AddToCompareStyles
      role="presentation"
      onClick={(e) => e.stopPropagation()}
    >
      <Checkbox
        checked={hasAddMoreToCompare ? isSelected : isChecked}
        defaultChecked={false}
        onChange={handleAddToCompare}
        className="size-16"
      >
        {isSelected || isChecked
          ? t('comparison.addedCompare')
          : t('comparison.addCompare')}
      </Checkbox>
    </AddToCompareStyles>
  );
};

const AddToCompareStyles = styled.div`
  padding: 5px 0;

  @media (max-width: 576px) {
    .ant-checkbox-wrapper {
      font-size: 14px;
    }
  }
`;

AddToCompare.propTypes = {
  item: PropTypes.object,
  handleClickAddItem: PropTypes.func,
  handleClickRemoveItem: PropTypes.func,
  hasAddMoreToCompare: PropTypes.bool,
  listCentresAdded: PropTypes.array,
};

export default AddToCompare;
