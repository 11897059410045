import { createDraftSafeSelector } from '@reduxjs/toolkit';

const getRawCentresCompare = (state) => state.centresCompare?.selectedCentres;
const getRawCentresCompareInitSlide = (state) =>
  state.centresCompare?.initialSlide;
const getResourceFilterCompare = (state) =>
  state.centresCompare?.resourceFilter;

export const getCentresCompareSelector = createDraftSafeSelector(
  [getRawCentresCompare],
  (data) => data,
);

export const getCentresCompareInitSlide = createDraftSafeSelector(
  [getRawCentresCompareInitSlide],
  (data) => data,
);

export const getResourceFilterCompareSelector = createDraftSafeSelector(
  [getResourceFilterCompare],
  (data) => data,
);
